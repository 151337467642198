<template>
  <div>
    <v-layout wrap justify-center style="background-color: #f0f0f0">
      <v-flex xs12 text-center py-4 pt-8>
        <span
          style="font-size: 25px; font-family: OutfitBold; font-weight: 500"
        >
          Privacy Policy
        </span>
      </v-flex>
      <v-flex px-2 xs12 sm11 md10 lg9 xl8 text-justify>
        <span style="font-family: JostMedium; font-size: 18px; color: #1a1919"
          >Effective Date: 10th April 2024 Thank you for choosing Kumarakom
          Ventures for your diverse business needs. This Privacy Policy outlines
          how we collect, use, disclose, and protect your personal information
          when you use our services. We are committed to safeguarding your
          privacy and ensuring the security of your personal data.
          <br />
          <br />
          <ul>
            <span style="font-family: JostSemiBold"
              >1. Information We Collect:</span
            >
            <br />
            <ul>
              <li>
                Personal Information: When you engage with Kumarakom Ventures,
                we may collect personal information such as your name, email
                address, phone number, postal address, and other relevant
                details necessary for providing our services.
              </li>
              <li>
                Payment Information: If you make payments for our services, we
                may collect payment-related information, such as credit card
                details or other financial data. However, we do not store
                payment information directly. We utilize secure third-party
                payment processors to handle transactions securely.
              </li>
              <li>
                Usage Data: We may collect information about how you interact
                with our website, including IP addresses, browser types,
                operating systems, referring pages, and the timestamps of
                visits.
              </li>
              <li>
                Cookies: We use cookies and similar tracking technologies to
                enhance your browsing experience and collect information about
                your preferences and activities on our website.
              </li>
            </ul>
          </ul>
          <br />
          <ul>
            <span style="font-family: JostSemiBold"
              >2. How We Use Your Information:</span
            >
            <br />
            <ul>
              <li>
                We may use the information collected to provide, maintain, and
                improve our services, including customer support, marketing, and
                communication purposes.
              </li>
              <li>
                Your personal information may be used to process transactions,
                send invoices, and manage your account.
              </li>
              <li>
                We may use usage data and cookies to analyze trends, administer
                the website, track users' movements around the site, and gather
                demographic information.
              </li>
            </ul>
          </ul>
          <br />
          <ul>
            <span style="font-family: JostSemiBold"
              >3. Data Sharing and Disclosure:</span
            >
            <br />
            <ul>
              <li>
                Kumarakom Ventures does not sell, rent, or lease your personal
                information to third parties.
              </li>
              <li>
                We may share your information with trusted third-party service
                providers who assist us in operating our website, conducting our
                business, or serving you, provided that they agree to keep your
                information confidential.
              </li>
              <li>
                We may disclose your personal information if required by law or
                in response to valid legal requests.
              </li>
            </ul>
          </ul>
          <br />
          <ul>
            <span style="font-family: JostSemiBold">4. Data Security:</span>
            <br />
            <ul>
              <li>
                We implement appropriate technical and organizational measures
                to protect your personal information against unauthorized
                access, alteration, disclosure, or destruction.
              </li>
              <li>
                However, no method of transmission over the internet or
                electronic storage is completely secure. Therefore, while we
                strive to use commercially acceptable means to protect your
                personal information, we cannot guarantee its absolute security.
              </li>
            </ul>
          </ul>
          <br />
          <ul>
            <span style="font-family: JostSemiBold">5. Your Rights:</span>
            <br />
            <ul>
              <li>
                You have the right to access, correct, or delete your personal
                information. If you wish to exercise these rights, please
                contact us using the contact details provided below.
              </li>
              <li>
                You may opt-out of receiving marketing communications from us by
                following the unsubscribe instructions included in such
                communications.
              </li>
            </ul>
          </ul>
          <br />
          <ul>
            <span style="font-family: JostSemiBold"
              >6. Children's Privacy:</span
            >
            <br />
            <ul>
              <li>
                Our services are not directed to individuals under the age of
                18. We do not knowingly collect personal information from
                children. If you are a parent or guardian and become aware that
                your child has provided us with personal information, please
                contact us, and we will take steps to remove such information
                from our systems.
              </li>
            </ul>
          </ul>
          <br />
          <ul>
            <span style="font-family: JostSemiBold"
              >7. Changes to This Privacy Policy:</span
            >
            <br />
            <ul>
              <li>
                We reserve the right to update or change our Privacy Policy at
                any time. Any changes will be effective immediately upon posting
                the updated Privacy Policy on this page.
              </li>
            </ul>
          </ul>
          <br />
          <ul>
            <span style="font-family: JostSemiBold">8. Contact Us:</span>
            <br />
            <ul>
              <li>
                If you have any questions or concerns about our Privacy Policy
                or the handling of your personal information, please contact us
                at [insert contact email/phone/address].
              </li>
            </ul>
          </ul>
          <br />
          By using our services, you consent to the collection and use of your
          personal information as outlined in this Privacy Policy. Thank you for
          entrusting Kumarakom Ventures with your business needs.
          <br />
          <br />
          <ul>
            <span style="font-family: JostSemiBold"
              >9. Version Control and Accountable Officers</span
            >
            <br />
            <ul>
              It is the joint responsibility of the management to ensure
              compliance with this policy.
            </ul>
          </ul>
          <br />
          <table style="width: 100%">
            <tr>
              <td>Last Approval</td>
              <td>10th April 2024</td>
            </tr>
            <tr>
              <td>Review Date</td>
              <td>10th April 2025</td>
            </tr>
            <tr>
              <td>Approved by</td>
              <td>Directors</td>
            </tr>
            <tr>
              <td>Version Control Version </td>
              <td>Version 1</td>
            </tr>
          </table>
          <br>
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
//   import axios from "axios";
export default {
  data() {
    return {
      pageData: {},
    };
  },
  beforeMount() {
    //   this.getData();
  },
  methods: {
    //   getData() {
    //     axios({
    //       method: "GET",
    //       url: "/static/getbyname",
    //       params: {
    //         Name: "Privacy Policy",
    //       },
    //     })
    //       .then((response) => {
    //         this.pageData = response.data.data;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   },
  },
};
</script>